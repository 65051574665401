import React from 'react'
import "./Topic.css";
// import "../../App.css";

const Topic = ({ topicName, handleTopic, serviceId ,id }) => {
    return (
        // <button className={`btn btn-topic ${serviceId}`} onClick={handleTopic}>{topicName}</button>
        <button id={id} className={`btn-topic btn ${serviceId}`} onClick={handleTopic}>{topicName}</button>
    )
}

export default Topic