import React, { useContext ,useState} from 'react'
import { ServiceContext } from '../../contexts/ServiceContext';
import { UserContext } from '../../contexts/UserContext';
import "./SystemConfirmation.css";
import { RiCloseLine } from "react-icons/ri";
import MeetingDetails from '../../components/meetingDetails/MeetingDetails';

const SystemConfirmation = () => {
    const [isNoticeOpen,setIsNoticeOpen] = useState(true);
    const handleCloseNotice=()=>{
        setIsNoticeOpen(false);
    }
    return (
        <div className="page-container system-confirmation">
           {isNoticeOpen&& <div className="welcome-container system-welcome">
                <span id="confirmation-notice">Done! Your meeting was scheduled successfully. See you soon!</span>
                <RiCloseLine className="close-notice-icon" onClick={handleCloseNotice} style={{cursor:"pointer"}}/>
            </div>}
            <MeetingDetails/>
            <div className="buttons-container btns-remarks">
                <button id="add-to-calendar" className="btn center btn-color btn-continue">Add to my calendar</button>
                <button id="download-iCal" className="btn center btn-color btn-continue">Download as iCal</button>
                <button id="print" className="btn center btn-color btn-continue">Print</button>
            </div>
            
        </div>
    )
}

export default SystemConfirmation