import React, { useContext, useEffect } from 'react'
import { UserContext } from "../../contexts/UserContext";
import Welcome from '../../components/welcome/Welcome';
import "./UserConfirmation.css";
import { useNavigate } from 'react-router-dom';
import { ServiceContext } from '../../contexts/ServiceContext';
import MeetingDetails from '../../components/meetingDetails/MeetingDetails';


const UserConfirmation = () => {

    const navigate = useNavigate()
    const { username} = useContext(UserContext);

    const handleSchedule = () => {
        // console.log("schedule my meeting")
        navigate("/systemConfirmation");
    }

    return (
        <div className="page-container">
            <div className="welcome-container">
                <span id="user-confirmation-message">Please <span id="confirm">confirm</span> that the following details are correct before scheduling your meeting.</span>
            </div>
            <MeetingDetails/>
            <div className="buttons-container btns-remarks margin-top">
                <button id="user-confirmation-back-button" className="btn btn-back" onClick={() => navigate(-1, { state: { username } })}>back</button>
                <button id="user-confirmation-schedule-button" className="btn btn-secondary btn-continue" onClick={handleSchedule}>Schedule My Meeting</button>
            </div>

        </div>
    )
}

export default UserConfirmation