import React from 'react'

const Scheduler = ({time,timeSlots,handleContinue,handleChooseHour,isRadioSelected}) => {
    return (
        // <div>Scheduler</div>
        <div className={`scheduler-container ${time}`}>

            <p>{time}</p>
            <div className="scheduler-container-hours">
                <span className="scheduler-hours">{timeSlots[0][time].map((hour, index) => {
                    return (
                        <div className="btn-radio-container" key={index} onSubmit={handleContinue}>
                            <input id={`${time}-hour-${index}`} type="radio" name="time-slot" onChange={handleChooseHour} value={hour} className="hide-box" checked={isRadioSelected(hour)} ></input>
                            <label className="btn-radio" htmlFor={hour} > {hour}</label>
                        </div>
                    )
                })}
                </span>
            </div>
        </div>
    )
}

export default Scheduler