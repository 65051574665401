import React,{useContext} from 'react'
import { Navigate } from 'react-router-dom';
import{UserContext} from "../contexts/UserContext";

const RequireAuth = ({children}) => {
    const {username} = useContext(UserContext);
    // console.log(username);
    // const {username} = useContext(UserContext);
    if(username==="") {
       return <Navigate to="/login" />
    }

    return children;
}

export default RequireAuth