import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Topic from '../topic/Topic';
import "./TopicList.css";
import TOPICS from "../../files/topics.json";
import { ServiceContext } from '../../contexts/ServiceContext';
const TopicList = ({closeDialog}) => {

    const { service, serviceId,handleTopic } = useContext(ServiceContext);

    // const [selectedTopic, setSelectedTopic] = useState("");
    const [topicListByService, setTopicListByService] = useState([]);

    let navigate = useNavigate();

    const handleChooseTopic = (topic) => {
        // setSelectedTopic(topic);
        handleTopic(topic);
        closeDialog();
        //go to setTime screen
        navigate("setTime", { state: { service, topic }});
        // navigate("setTime");
    }

    // const TOPICS = ["Topic 1", "Topic 2", "Topic 3"];        
    const getTopicsByService = () => {
        const topicsByServiceArr = TOPICS.filter((topicList) => {
            return topicList.service === service;
        })
        // console.log("topicsByServiceArr", topicsByServiceArr[0].serviceTopics)    
        // console.log("topicsByServiceArr", topicsByServiceArr[0].serviceTopics)    
        setTopicListByService(topicsByServiceArr[0].serviceTopics);
    }

    const mapTopics = () => {
        
        return topicListByService.map((topic, i) => {
            return (
                <Topic key={i} id={`topic-${i+1}`} serviceId={serviceId} topicName={topic} handleTopic={() => handleChooseTopic(topic)} />
            )
        })
    }

    useEffect(() => {
        getTopicsByService();
        mapTopics();
    }, [service, topicListByService,getTopicsByService.apply,mapTopics])


    return (
        <div className="topic-list">{topicListByService && mapTopics()}</div>
    )
}

export default TopicList