import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Welcome from '../../components/welcome/Welcome';
import { ServiceContext } from '../../contexts/ServiceContext';
import { UserContext } from '../../contexts/UserContext';
import "./ContactAndRemarks.css";

const ContactandRemarks = () => {

    const [error, setError] = useState("")
    const { service, topic, time } = useContext(ServiceContext);
    const { username, email, setEmail, phone, setPhone, remarks, setRemarks } = useContext(UserContext)
    const navigate = useNavigate();

    const validateEmail = (emailAddress) => {
        if (emailAddress.length === 0) {
            return true;
        }
        let regex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        // console.log(regex.test(emailAddress));
        return regex.test(emailAddress);
    }

    const validatePhoneNumber = (phoneNumber) => {
        if (phoneNumber.length === 0) {
            return true;
        }
        let onlyDigits = phoneNumber.split("-")
        // console.log(onlyDigits)
        // console.log(onlyDigits.join("").split(""))
        if (onlyDigits.join("").split("").length < 7) {
            return false;
        }
        let regex = /^[0-9 -]+$/;
        return regex.test(phoneNumber);
    }

    const handleContinue = () => {
        if (!validateEmail(email)) {
            setError("Bad Email or Phone Number")
            return;
        }
        if (!validatePhoneNumber(phone)) {
            setError("Bad Email or Phone Number")
            return;
        }
        else {
            setError("");
            navigate("/userConfirmation");
        }

    }

    return (
        <div className="page-container">
            <div className="welcome-container">
                <Welcome username={username} />
                <span> {`You are setting up a meeting with `}
                    <span id={`service-${service}`}>{service} </span>
                    about
                    <span id={`topic-${topic}`}> {topic} </span>
                    at
                    <span id={`time-${time}`}> {time}.</span>
                </span>
            </div>
            <div className="select-time"><b>Please fill in your contact data</b></div>
            <div className="section">
                <form className="form contact-details">
                    <div className="form-input email">
                        <label htmlFor="email-input">Email</label>
                        <input
                            type="email"
                            id="email-input"
                            autoComplete="on"
                            onChange={(e) => { setEmail(e.target.value) }}
                            value={email}
                            required
                        />
                    </div>
                    <div className="form-input phone">
                        <label htmlFor="phone-input">Mobile Phone </label>
                        <input
                            type="phone"
                            id="phone-input"
                            autoComplete="on"
                            onChange={(e) => { setPhone(e.target.value) }}
                            value={phone}
                            required
                        />
                        <p className="phone-comment">Phone number must be at least 7 digits long</p>
                    </div>
                    <div className="form-input remarks">
                        <label htmlFor="remarks-input">Remarks </label>
                        <textarea
                            placeholder="Enter remarks"
                            rows="10"
                            cols="38"
                            id="remarks-input"
                            autoComplete="on"
                            onChange={(e) => { setRemarks(e.target.value) }}
                            value={remarks}
                            required
                        />
                    </div>
                    {error && <div id="error-message" className="error-message">{error}</div>}
                </form>
            </div>
            <div className="buttons-container btns-remarks">
                <button id={"remarks-back-button"} className="btn btn-back" onClick={() => navigate(-1, { state: { username } })}>back</button>
                <button id={"remarks-continue-button"} className="btn btn-secondary btn-continue" disabled={email || phone ? false : true} onClick={handleContinue}>continue</button>
            </div>

        </div>
    )
}

export default ContactandRemarks