import React, { useRef, useState, useEffect, useContext } from 'react'

import { useNavigate } from 'react-router-dom';
import { BsExclamationTriangle } from "react-icons/bs"
import { UserContext } from '../../contexts/UserContext';
import { BiHide, BiShowAlt } from "react-icons/bi";
import Links from "../../files/links.json"
import "./Login.css";

const Login = () => {
    const { username, login, errMessage, setErrMessage } = useContext(UserContext);

    const userRef = useRef();
    let navigate = useNavigate();
    const [user, setUser] = useState("");
    const [pwd, setPwd] = useState("")
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);
    const [inputType, setInputType] = useState("password");


    useEffect(() => {
        userRef.current.focus();
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();

        setErrMessage("");
        login(user, pwd);

        navigate("/")
    }
    const hideShowPassword = () => {
        setIsPasswordHidden((prev) => !prev)
        if (isPasswordHidden) {
            setInputType("text");
        }
        else {
            setInputType("password");
        }
    }

    // console.log(Links[0]['provengo tutorials'])
    return (
        <>
            <div className="login-page-container">
                <div className="login">

                    <div className="not-a-real-bank">
                        Provengo Bank is part of the <a href={Links[0]['provengo tutorials']}>Provengo Tutorials.</a><br /> <b>IT IS NOT A REAL BANK.</b>
                    </div>
                    <h2 className="login-title">Login</h2>
                    <p className="enter-credentials">To view your dashboard, please enter your credentials</p>
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="form-input username">
                            <label htmlFor="username-input-container">
                                <input
                                    type="text"
                                    id="username-input-container"
                                    ref={userRef}
                                    autoComplete="on"
                                    onChange={(e) => { setUser(e.target.value) }}
                                    value={user}
                                    required
                                    placeholder="Username"
                                />
                            </label>
                        </div>
                        <div className="form-input password">
                            <label htmlFor="password-input-container">
                                <input
                                    type={inputType}
                                    id="password-input-container"
                                    autoComplete="on"
                                    onChange={(e) => { setPwd(e.target.value) }}
                                    value={pwd}
                                    required
                                    placeholder="Password"

                                />
                            </label>
                            <span className="hide-password" onClick={hideShowPassword}>
                                {isPasswordHidden ?
                                    <BiShowAlt className="eye" /> :
                                    <BiHide className="eye" />
                                }
                            </span>
                        </div>
                        <div className="btns">
                            <a href={Links[0]["provengo tech"]} id="cancel-button-container" className="btn cancel-btn">Cancel</a>
                            <button id="login-button" className="btn login-btn btn-secondary" onSubmit={handleSubmit}>Login</button>
                        </div>
                    </form>
                    {errMessage && !username && <div id="error-message-container" className="error-message">
                        <BsExclamationTriangle className="exclamation-mark" />
                        <span>
                            {errMessage}
                        </span>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default Login

