import React from "react";
import "./SetTimeAndBranch.css";

const SetTimeAndBranch = ({ setSelectedBranch }) => {
    const handleClick = (e) => {
        // console.log(e.target.value);
        setSelectedBranch(e.target.value);
    }

    return (
        <div className="set-time-and-branch">
            <div className="form-input">
                <label htmlFor="branch-input">Branch: </label>
                <select
                    className="btn select-branch-input"
                    name="branch-input"
                    list="branches"
                    id="branch-input"
                    onClick={handleClick}
                    required>
                    <option value="Tel Aviv Central">Tel Aviv Central</option>
                    <option value="Ashdod">Ashdod</option>
                    <option value="Beer Sheva">Beer Sheva</option>
                    <option value="Haifa">Haifa</option>
                    <option value="Ramat Gan">Ramat Gan</option>
                </select>
            </div>
        </div>
    )
}

export default SetTimeAndBranch