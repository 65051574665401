import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/header/Header';
import Dashboard from './pages/dashbords/Dashboard';
import Login from './pages/login/Login';
import SetTime from './pages/setTime/SetTime';
import './App.css';
import NoMatch from './pages/NoMatch';
import RequireAuth from './components/RequireAuth';
import SetTimeAndBranch from './pages/setTimeAndBranch/SetTimeAndBranch';
import ContactAndRemarks from './pages/contactAndRemarks/ContactAndRemarks';
import { ServiceProvider } from './contexts/ServiceContext';
import UserConfirmation from './pages/userConfirmation/UserConfirmation';
import SystemConfirmation from './pages/systemConfirmation/SystemConfirmation';


// https://dummy-bank.provengo.tech


function App() {
  const [errMessage, setErrMessage] = useState("");
  return (
    <div className="App">
      <ServiceProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/login" exact="true" element={<Login errMessage={errMessage} setErrMessage={setErrMessage} />} />
            <Route path="/" exact="true" element={<RequireAuth><Dashboard /></RequireAuth>} />
            <Route path="/setTime" replace={true} exact="true" element={<RequireAuth><SetTime /></RequireAuth>} />
            <Route path="/setTimeAndBranch" exact="true" element={<RequireAuth><SetTimeAndBranch /></RequireAuth>} />
            <Route path="/contactAndRemarks" exact="true" element={<RequireAuth><ContactAndRemarks /></RequireAuth>} />
            <Route path="/userConfirmation" exact="true" element={<RequireAuth><UserConfirmation /></RequireAuth>} />
            <Route path="/systemConfirmation" exact="true" element={<RequireAuth><SystemConfirmation /></RequireAuth>} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </ServiceProvider>
    </div>
  );
}

export default App;
