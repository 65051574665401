import React from 'react'
import TopicList from '../../components/topicList/TopicList';
import './ChooseTopic.css';

const ChooseTopic = ({ closeDialog, handleCancel, service }) => {
  return (
    <div className="dialog" id="choose-topic-dialog">
      <p className="dialog-title">{`Please choose a topic for your meeting with `}<span id="service-name">{service}</span></p>
      <TopicList closeDialog={closeDialog} />
      <button id="dialog-cancel-button" className="btn center dialog-cancel-btn" onClick={handleCancel}>Cancel</button>
    </div>
  )
}

export default ChooseTopic