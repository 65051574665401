import React, { useState, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ServiceContext } from "../../contexts/ServiceContext";
import Backdrop from "../../components/backdrop/Backdrop";
import ServiceButton from "../../components/serviceButton/ServiceButton";
import Welcome from "../../components/welcome/Welcome";
import ChooseTopic from "../../components/chooseTopic/ChooseTopic";

import "./Dashboard.css";

const Dashboard = () => {
  const { username } = useContext(UserContext);
  const { service, isDialogOpen, setIsDialogOpen, serviceId } =
    useContext(ServiceContext);
  const [selectedService, setSelectedService] = useState("");


  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCancel = () => {
    closeDialog();
  };

  return (
    <div className="container">
      <div className="dashboard-welcome">
        <Welcome username={username} />
      </div>
      <div className="dashboard-container">
        <section className="left-section images">
          <img
            className="fun-data-img"
            src="https://cdn.pixabay.com/photo/2021/10/18/22/48/bar-chart-6722290_960_720.png"
          />
          <img
            className="fun-data-img"
            src="https://cdn.pixabay.com/photo/2021/10/18/22/48/area-chart-6722288_960_720.png"
          />
          <img
            className="fun-data-img"
            src="https://cdn.pixabay.com/photo/2021/10/18/22/48/doughnut-6722291_960_720.png"
          />
        </section>
        <section className="right-section">
          <h4>Schedule a meeting with:</h4>
          <div className="right-section-buttons">
            <ServiceButton
              id="personal-banker-service-button"
              serviceNumber={0}
              name={"Personal Banker"}
              selectedService={selectedService}
            />
            <ServiceButton
              id="investment-specialist-service-button"
              serviceNumber={1}
              name={"Investment Specialist"}
              selectedService={selectedService}
            />
            <ServiceButton
              id="cashier-service-button"
              serviceNumber={2}
              name={"Cashier"}
              selectedService={selectedService}
            />
          </div>
          <h3 className="error-message db-error">
            ProvengoBank is part of the Provengo tutorials, and not a real bank.
          </h3>
        </section>
      </div>
      {isDialogOpen && (
        <ChooseTopic
          handleCancel={handleCancel}
          service={service}
          serviceId={serviceId}
          closeDialog={closeDialog}
        />
      )}
      {isDialogOpen && <Backdrop onClick={closeDialog} />}
    </div>
  );
};

export default Dashboard;
