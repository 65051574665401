import React from 'react'
import "./Welcome.css";

const Welcome = ({ username }) => {
    return (
        //id for username container
        <h4 className="hello" >Hello, <span  className="username" id="username-text">{username}!</span></h4>
    )
}

export default Welcome