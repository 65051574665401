import { createContext, useState } from "react";
// import { useNavigate } from "react-router";

export const ServiceContext = createContext("");

export function ServiceProvider({ children }) {

    const [service, setService] = useState("");
    const [serviceId, setServiceId] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [topic, setTopic] = useState("");
    const [time,setTime] = useState("");
    const [branch,setBranch] = useState("");
    // let navigate = useNavigate();

    const handleChooseService = (serviceNumber) => {
        setIsDialogOpen(true);
        if (serviceNumber === 0) {
            setService("Personal Banker");
            console.log("PB")
            setServiceId("personal-banker-service")
        }
        else if (serviceNumber === 1) {
            setService("Investment Specialist")
            setServiceId("investment-specialist-service")
        }
        else {
            setService("Cashier")
            setServiceId("cashier-service")
        }
    }

    const handleTopic = (topic) => {
        setTopic(topic);
        console.log(topic);
        // closeDialog();
        //go to setTime screen
        // navigate("setTime", { state: { service, topic } });
        // navigate("setTime");
        // , { state: { service, topic } });
    }

    const value = { service, setService, handleChooseService, isDialogOpen, setIsDialogOpen, serviceId, setServiceId, topic, setTopic ,handleTopic,time,setTime,branch,setBranch};
    return (
        <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
    )
}