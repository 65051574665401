import React, { useContext,useEffect } from 'react'
import { useNavigate } from 'react-router';
import { ServiceContext } from '../../contexts/ServiceContext';
import { UserContext } from '../../contexts/UserContext';
import "./MeetingDetails.css";
const MeetingDetails = () => {
    
    const { email, phone, remarks } = useContext(UserContext);
    const { service, topic, time, branch, setBranch } = useContext(ServiceContext);

    const isHomeBranch = () => {
        if (service !== "Cashier") {
            setBranch("Home Branch")
        }
    }

    useEffect(() => {
        isHomeBranch();
    }, [])

    return (
        <ul className="meeting-details">
            <li className="meeting-item"><h4>Service</h4><div id="user-confirmation-service">{service}</div></li>
            <li className="meeting-item"><h4>Topic</h4><div id="user-confirmation-topic">{topic}</div></li>
            <li className="meeting-item"><h4>Time</h4><div id="user-confirmation-time">{time}</div></li>
            <li className="meeting-item"><h4>Branch</h4><div id="user-confirmation-branch">{branch}</div></li>
            {email && <li className="meeting-item"><h4>Email</h4><div id="user-confirmation-email">{email}</div></li>}
            {phone && <li className="meeting-item"><h4>Mobile Phone</h4><div id="user-confirmation-phone">{phone}</div></li>}
            {remarks && <li className="meeting-item"><h4>Remarks</h4><div id="user-confirmation-remarks">{remarks}</div></li>}
        </ul>
    )
}

export default MeetingDetails