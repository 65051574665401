import { createContext, useState } from "react";
import usersData from '../files/users.json';


export const UserContext = createContext(null);

export function UserProvider({ children }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errMessage, setErrMessage] = useState(null);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [remarks, setRemarks] = useState("");
    
    const login = (user, pwd) => {
        usersData.find((userData) => {
            if (userData.username === user && userData.password === pwd) {
                setErrMessage(null);
                setUsername(user);
                setPassword(pwd);
                return;
            }
        })
        setErrMessage("Bad username or password. Please consult the tutorial if you’re not sure what username and password you should use.")
    }

    const logout = () => {
        setUsername(null);
    }

    const value = { username, setUsername, password, setPassword, login, logout,errMessage,setErrMessage,email,setEmail ,phone,setPhone,remarks,setRemarks};
    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    )
}