import React, { useContext, useState } from 'react'
import { BsArrowRight } from "react-icons/bs";

import { ServiceContext } from '../../contexts/ServiceContext';
import "./ServiceButton.css";
const Button = ({ name, id, serviceNumber }) => {

  const { handleChooseService } = useContext(ServiceContext);
  const [isArrowHovered, setIsArrowHovered] = useState(false);

  return (
    <div
      className="service-button"
      onClick={() => handleChooseService(serviceNumber)}>
      <button className={`btn btn-${serviceNumber}`} id={id}
        onMouseOver={() => { setIsArrowHovered(true) }}
        onMouseLeave={() => { setIsArrowHovered(false) }}
      >
        {name}
        <BsArrowRight className={isArrowHovered ? "arrow-hovered" : "arrow-icon"} />
      </button>
    </div>
  )
}

export default Button