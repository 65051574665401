import React from 'react'
import './Header.css';

const Header = () => {
  return (<>
    <div className="header">
      <div className="header-item logo"><img src="http://mbarsinai.com/testory/tool/docs/site/_/img/logo.png" /></div>
      <div className="header-item text">Provengo<i><b>Bank</b></i></div>
    </div>
  </>

  )
}

export default Header